<template>
    <el-container>
      <el-main>
          <el-table :data="tableData" border stripe height="500">
            <el-table-column prop="id" label="id">
            </el-table-column>
            <el-table-column prop="starttimeStr" label="开始时间">
            </el-table-column>
            <el-table-column prop="endtimeStr" label="结束时间">
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                {{ statusName(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog title="表单弹窗" :visible.sync="dialogVisible" width="30%">
          <el-form lable-width="80px">
            <el-form-item label="id">
              <el-input v-model="form.id" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="北京时间">
                <div class="block">
                    <el-date-picker
                    v-model="form.timeArr"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-select v-model="form.status" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
            </el-select>
          </el-form>
          <span slot="footer" class="dialo-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="handleConfirm">确 定</el-button>
          </span>
          </el-dialog>
      </el-main>
    </el-container>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    name: 'HbPlan',
    components: {
    },
    data() {
      return {  
        tableData: [],
        dialogVisible: false,
        form: {
          timeArr: [],
          rowEditing: 0,
          id: 0,
          status: 0,
        },
        options: [
          {value: 0, label: '未启用'},
          {value: 1, label: '启用'},
        ],
      }
    },
    methods: {
      handleEdit(index) {
        this.dialogVisible = true
        var start = new Date(index.starttimeStr)
        var end = new Date(index.endtimeStr)
        this.form.timeArr = [start, end]
        this.form.id = index.id
        this.form.status = index.status
        this.form.rowEditing = index
      },
      handleConfirm() {
        var _this = this
        axios.post('/api/admin/editPlanRow', {id: this.form.id.toString(), starttime: this.getTimeStart, endtime: this.getTimeEnd, status: this.form.status.toString()})
        .then(response => {
          if(response.data.code == 0) {
            _this.dialogVisible =false
            // location.reload()
            var index = _this.form.rowEditing
            index.starttimeStr = this.getTimeStart
            index.endtimeStr = this.getTimeEnd
            index.status = this.form.status
            _this.$message({
              message: response.data.msg,
              type: 'success'
            });
          }
        })
      },
      statusName(sts) {
        sts = parseInt(sts)
        var stsName = '状态错误'
        this.options.forEach(item => {
          if(sts == item.value) {
            stsName = item.label
            return
          }
        });
        return stsName
      },
      getDefaultPage(pageN) {
        if (pageN === null) {
          pageN = 0
        }
        var _this = this
        axios.get('/api/admin/hbPlan')
        .then(response => {
          // console.log(response.data)
          if(response.data.code == 0) {
            _this.tableData = JSON.parse(response.data.dataList)
          }
        })
        .catch(function (error) {
            if(error.response.status == 404 && error.response.data.code == 99) {
            _this.$message({
                type: 'error',
                message: '您还未登录'
            })
            window.location.href = '/#/admin-login/'
            }
        })
      },
      handleTime(time) {
        var year, month, mDate, hours, minutes, seconds
        year = time.getFullYear()
        month = (time.getMonth()+1).toString().padStart(2, '0')
        mDate = (time.getDate()).toString().padStart(2, '0')
        hours = (time.getHours()).toString().padStart(2, '0')
        minutes = (time.getMinutes()).toString().padStart(2, '0')
        seconds = (time.getSeconds()).toString().padStart(2, '0')
        return `${year}-${month}-${mDate} ${hours}:${minutes}:${seconds}`
      }
    },
    mounted () {
      document.title = '红包计划'
      this.$emit('getMessage', {mtitle: '红包计划', isLoginPage: false});
      this.getDefaultPage()
    },
    computed: {
        getTimeStart() {
            var start = new Date(this.form.timeArr[0])
            var starttime = this.handleTime(start)
            return starttime
        },
        getTimeEnd() {
            var end = new Date(this.form.timeArr[1])
            var endtime = this.handleTime(end)
            return endtime
        }
    }
  }
  </script>