<template>
	<el-main>
		<el-upload
			class="upload-demo"
			drag
			action="/api/admin/uploadCj"
			accept=".xlsx"
			:on-success="handleSuccess"
			:on-error="handleError"
			>
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			<div class="el-upload__tip" slot="tip">只能上传.xlsx文件</div>
		</el-upload>
	</el-main>
</template>

<script>
export default {
	name: 'CjUpload',
	methods: {
		handleSuccess(response, file, fileList) {
			console.log(file, fileList)
			if(response.code == 0) {
				this.$message({
					type: 'success',
					message: response.msg
				})
				window.location.href = '/#/caijin/'
			} else {
				this.$message({
					type: 'error',
					message: response.msg
				})
			}
		},
		handleError(err, file, fileList) {
			console.log(file, fileList)
			this.$message({
				type: 'error',
				message: '上传失败！'+err
			})
		}
	},
	mounted () {
		document.title = '彩金数据上传'
		this.$emit('getMessage', {mtitle: '彩金数据上传', isLoginPage: false});
	}
}
</script>