<template>
  <div id="app">
    <el-container style="height: 920px; border: 1px solid #eee">
      <el-aside v-if="!viewData.isLoginPage" width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu :default-openeds="['1', '2']">
          <el-submenu index="1">
            <template slot="title"><i class="el-icon-message"></i>红包数据</template>
            <el-menu-item-group>
              <el-menu-item index="1-1" @click="push('/hb-page/')">红包数据</el-menu-item>
              <el-menu-item index="1-2" @click="push('/hb-upload')">数据上传</el-menu-item>
              <el-menu-item index="1-3" @click="push('/hb-plan/')">红包计划</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title"><i class="el-icon-message"></i>神秘彩金</template>
            <el-menu-item-group>
              <el-menu-item index="2-1" @click="push('/caijin/')">彩金数据</el-menu-item>
              <el-menu-item index="2-2" @click="push('/cj-upload/')">数据上传</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header v-if="!viewData.isLoginPage" style="text-align: right; font-size: 18px">
          <el-row>
            <el-col :span="12"><div class="grid-content" style="text-align: left;">{{viewData.mtitle}}</div></el-col>
            <el-col :span="12">
              <div class="grid-content">
                <el-dropdown>
                <i class="el-icon-setting" style="margin-right: 15px"></i>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><a href="/#/admin-login/">退出</a></el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
                <span>管理员</span>
              </div>
            </el-col>
          </el-row>
        </el-header>
        <router-view @getMessage="showMsg"></router-view>
      </el-container>
    </el-container>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      viewData: {
        mtitle: '红包彩金后台',
        isLoginPage: false,
      }
    }
  },
  methods: {
    push(url) {
      this.$router.push(url)
    },
    showMsg(val) {
      this.viewData = val
    }
  }
}
</script>

<style>
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  }

  a {
    text-decoration: none;
  }
</style>
