import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import VueRouter from "vue-router"
import HbPage from "./components/HbPage.vue"
import HbUpload from "./components/HbUpload.vue"
import HbPlan from "./components/HbPlan.vue"
import LoginPage from "./components/LoginPage.vue"
import CaijinPage from "./components/CaijinPage.vue"
import CjUpload from "./components/CjUpload.vue"

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  {
    path: "/hb-page/",
    component: HbPage,
    name: "HbPage",
  },
  {
    path: "/hb-upload/",
    component: HbUpload,
    name: "HbUpload",
  },
  {
    path: "/hb-plan/",
    component: HbPlan,
    name: "HbPlan"
  },
  {
    path: "/admin-login/",
    component: LoginPage,
    name: "LoginPage"
  },
  {
    path: "/caijin/",
    component: CaijinPage,
    name: "CaijinPage"
  },
  {
    path: "/cj-upload/",
    component: CjUpload,
    name: "CjUpload"
  }
]

const router = new VueRouter({
  routes // （缩写）相当于 routes: routes
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
