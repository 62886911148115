<template>
  <el-container>
    <el-main>
      <el-form :inline="true" :model="formSearch">
          <el-form-item label="appid">
            <el-input v-model="formSearch.appid" placeholder="会员appid"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="formSearch.status" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
        <el-button type="danger" @click="handleDelAllHb">清空所有数据</el-button>
        <el-table :data="tableData" border stripe height="680">
          <el-table-column prop="id" label="id">
          </el-table-column>
          <el-table-column prop="appid" label="appid">
          </el-table-column>
          <el-table-column prop="money" label="金额">
          </el-table-column>
          <el-table-column prop="createtimeStr" label="导入时间">
          </el-table-column>
          <el-table-column prop="updatetimeStr" label="更新时间">
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              {{ statusName(scope.row.status) }}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog title="表单弹窗" :visible.sync="dialogVisible" width="30%">
        <el-form lable-width="80px">
          <el-form-item label="id">
            <el-input v-model="form.id" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="金额">
            <el-input v-model="form.money"></el-input>
          </el-form-item>
          <el-select v-model="form.status" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        </el-form>
        <span slot="footer" class="dialo-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button @click="handleConfirm" type="primary">确 定</el-button>
        </span>
        </el-dialog>
    </el-main>
    <el-footer>
        <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-size="formSearch.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="formSearch.total">
        </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HbPage',
  components: {
  },
  data() {
    return {  
      tableData: [],
      dialogVisible: false,
      form: {
        rowEditing: 0,
        id: 0,
        money: 0,
        status: 0,
      },
      formSearch: {
        appid: '',
        status: 5,
        pageSize: 10,
        total: 0,
        currentPage: 0,
      },
      options: [
        {value: 0, label: '未处理'},
        {value: 1, label: '已抢未派彩'},
        {value: 2, label: '已抢已派彩'},
        {value: 3, label: '已抢派彩中'},
        {value: 4, label: '已抢派彩失败'},
      ],
      options1: [
        {value: 5, label: '全部'},
        {value: 0, label: '未处理'},
        {value: 1, label: '已抢未派彩'},
        {value: 2, label: '已抢已派彩'},
        {value: 3, label: '已抢派彩中'},
        {value: 4, label: '已抢派彩失败'},
      ],
    }
  },
  methods: {
    handleEdit(index) {
      this.dialogVisible = true
      this.form.id = index.id
      this.form.money = index.money
      this.form.status = index.status
      this.form.rowEditing = index
    },
    handleDelete(index) {
      var _this = this
      this.$confirm('确定删除该条数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.post('/api/admin/delRow/'+index.id)
        .then((response) => {
          if(response.data.code == 0) {
            _this.tableData.splice(index.$index, 1)
            _this.$message({
              type: 'success',
              message: response.data.msg
            })
            _this.getDefaultPage(_this.currentPage)
          } else {
            _this.$message({
              type: 'info',
              message: response.data.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleConfirm() {
      var _this = this
      axios.post('/api/admin/editRow', {id: this.form.id.toString(), money: this.form.money.toString(), status: this.form.status.toString()})
      .then(response => {
        if(response.data.code == 0) {
          _this.dialogVisible =false
          // location.reload()
          var index = _this.form.rowEditing
          index.money = this.form.money
          index.updatetimeStr = response.data.updatetimeStr
          index.status = this.form.status
          _this.$message({
            message: response.data.msg,
            type: 'success'
          });
        }
      })
    },
    statusName(sts) {
      sts = parseInt(sts)
      var stsName = '状态错误'
      this.options.forEach(item => {
        if(sts == item.value) {
          stsName = item.label
          return
        }
      });
      return stsName
    },
    handleDelAllHb() {
      var _this = this
      this.$confirm('确定清空所有数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.post('/api/admin/delAllHb')
        .then(response => {
          if(response.data.code == 0) {
            _this.$message({
              type: 'success',
              message: response.data.msg
            })
            location.reload()
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleCurrentChange(val) { // 分页页数改变
      // console.log(val)
      var valNum = parseInt(val) -1
      this.currentPage = valNum
      var _this = this // valNum+'/'+this.pageSize
      axios.post('/api/admin/hbData', {page: valNum.toString(), offset: this.formSearch.pageSize.toString(), appid: this.formSearch.appid, status: this.formSearch.status.toString()})
      .then(response => {
        if(response.data.code == 0) {
          _this.tableData = JSON.parse(response.data.dataList)
          _this.formSearch.total = response.data.total
        } else {
          _this.$message({
            type: 'error',
            message: response.data.msg
          })
        }
      })
    },
    handleSizeChange(val) { // 每页条数改变
      this.pageSize = val
      this.getDefaultPage()
    },
    getDefaultPage(pageN) {
      if (!pageN) {
        pageN = 0
      }
      var _this = this
      axios.post('/api/admin/hbData', {page: pageN.toString(), offset: this.formSearch.pageSize.toString(), appid: this.formSearch.appid, status: this.formSearch.status.toString()})
      .then(response => {
        // console.log(response.data)
        if(response.data.code == 0) {
          _this.tableData = JSON.parse(response.data.dataList)
          _this.formSearch.total = response.data.total
        }
      })
      .catch(function (error) {
        if(error.response.status == 404 && error.response.data.code == 99) {
          _this.$message({
            type: 'error',
            message: '您还未登录'
          })
          window.location.href = '/#/admin-login/'
        }
      })
    },
    handleSearch() {
      this.getDefaultPage()
    }
  },
  mounted () {
    document.title = '红包数据'
    this.$emit('getMessage', {mtitle: '红包数据', isLoginPage: false});
    this.getDefaultPage()
  }
}
</script>